import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';

function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error('Token não encontrado. Redirecionando para login.');
      navigate('/login', {
        state: { errorMessage: t('access_denied') },
      });
      return;
    }

    const fetchUserName = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Dados recebidos do backend:', response.data);
        if (response.data && response.data.name) {
          setUserName(response.data.name);
        } else {
          console.warn('Nome do usuário não encontrado na resposta.');
          setUserName(t('unknown_user'));
        }
      } catch (error) {
        console.error('Erro ao buscar os dados do usuário:', error);
        setError(t('error_fetching_user'));
      }
    };

    fetchUserName();
  }, [navigate, t]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('dashboard')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card shadow-none bg-transparent border-bottom border-2">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h4 className="mb-3 mb-md-0">
                  {t('welcome_message')}, {userName || t('loading')}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <p>{t('total_users')}</p>
                <h5>85,028</h5>
                <div id="chart2"></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <p>{t('page_views')}</p>
                <h5>42,892</h5>
                <div id="chart3"></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <p>{t('avg_session_duration')}</p>
                <h5>00:03:20</h5>
                <div id="chart4"></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10">
              <div className="card-body">
                <p>{t('bounce_rate')}</p>
                <h5>51.46%</h5>
                <div id="chart5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Dashboard); // Envolvendo o componente com o HOC.