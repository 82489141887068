import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Tickets.css';

function Tickets() {
  const { t } = useTranslation();

  const [filterStatus, setFilterStatus] = useState("All");
  const [tickets, setTickets] = useState([
    {
      id: 1,
      title: "Ticket #001",
      message: "This is the content of Ticket #001. Pending approval.",
      time: "9:51 AM",
      status: t("Pending"),
      userType: "Admin",
      color: "#0d6efd",
      content: [
        {
          type: "chat",
          value: [
            { sender: "Customer", message: "I need help with my account.", time: "9:30 AM" },
            { sender: "You", message: "Sure, let me check it for you.", time: "9:35 AM" },
            { sender: "Customer", message: "Here is a screenshot.", type: "image", value: "https://via.placeholder.com/300x200", time: "9:37 AM" },
            { sender: "You", message: "Got it! Let me respond with a quick audio note.", type: "audio", value: "https://www.w3schools.com/html/horse.mp3", time: "9:40 AM" },
            { sender: "Customer", message: "Please check this video.", type: "video", value: "https://www.w3schools.com/html/mov_bbb.mp4", time: "9:45 AM" },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Ticket #002",
      message: "This is the content of Ticket #002. Resolved successfully.",
      time: "4:32 PM",
      status: t("Resolved"),
      userType: "Support",
      color: "#28a745",
      content: [
        {
          type: "chat",
          value: [
            { sender: "Customer", message: "Thank you for resolving my issue.", time: "4:20 PM" },
            { sender: "You", message: "You're welcome! Let us know if you need anything else.", time: "4:25 PM" },
          ],
        },
      ],
    },
  ]);

  const [selectedTicket, setSelectedTicket] = useState(tickets[0]);
  const [chatMessages, setChatMessages] = useState(selectedTicket.content[0]?.value || []);

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setChatMessages(ticket.content[0]?.value || []);
  };

  const handleOpenTicket = () => {
    const newTicket = {
      id: tickets.length + 1,
      title: `Ticket #00${tickets.length + 1}`,
      message: "New ticket created.",
      time: new Date().toLocaleTimeString(),
      status: t("Pending"),
      userType: "Customer",
      color: "#ffc107",
      content: [
        {
          type: "chat",
          value: [
            { sender: "Customer", message: "New ticket started.", time: new Date().toLocaleTimeString() },
          ],
        },
      ],
    };
    setTickets([newTicket, ...tickets]);
    setSelectedTicket(newTicket);
    setChatMessages(newTicket.content[0]?.value || []);
  };

  const handleCloseTicket = () => {
    const updatedTickets = tickets.map((ticket) =>
      ticket.id === selectedTicket.id
        ? { ...ticket, status: t("Resolved") }
        : ticket
    );
    setTickets(updatedTickets);
    setSelectedTicket(null);
    setChatMessages([]);
  };

  const handleSendMessage = (message) => {
    if (message.trim()) {
      const newMessage = { sender: "You", message, time: new Date().toLocaleTimeString() };
      setChatMessages([...chatMessages, newMessage]);

      // Atualizar o conteúdo do ticket selecionado
      const updatedTickets = tickets.map((ticket) =>
        ticket.id === selectedTicket.id
          ? {
              ...ticket,
              content: ticket.content.map((item) =>
                item.type === "chat"
                  ? { ...item, value: [...item.value, newMessage] }
                  : item
              ),
            }
          : ticket
      );

      setTickets(updatedTickets);
    }
  };

  const filteredTickets = tickets.filter((ticket) =>
    filterStatus === "All" ? true : ticket.status.toLowerCase() === filterStatus.toLowerCase()
  );

  return (
    <div className="page-wrapper" style={{ height: "100vh" }}>
      <div className="page-content" style={{ height: "100%" }}>
        <div className="tickets-wrapper d-flex" style={{ height: "100%" }}>
          {/* Sidebar Section */}
          <div className="tickets-sidebar" style={{ flex: "0 0 300px", overflowY: "auto" }}>
            <div className="tickets-sidebar-header">
              <div className="input-group input-group-sm mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="bx bx-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("searchTickets")}
                />
                <select
                  className="form-select form-select-sm ms-2"
                  onChange={(e) => setFilterStatus(e.target.value)}
                  value={filterStatus}
                >
                  <option value="All">{t("All Tickets")}</option>
                  <option value="Pending">{t("Pending")}</option>
                  <option value="Resolved">{t("Resolved")}</option>
                </select>
              </div>
              <button
                className="btn btn-primary btn-sm w-100 mt-3"
                onClick={handleOpenTicket}
              >
                {t("Open New Ticket")}
              </button>
            </div>

            <div className="tickets-list mt-4">
              <div className="list-group list-group-flush">
                {filteredTickets.map((ticket) => (
                  <a
                    key={ticket.id}
                    href="javascript:;"
                    className={`list-group-item ${
                      selectedTicket?.id === ticket.id ? "active" : ""
                    }`}
                    onClick={() => handleTicketClick(ticket)}
                  >
                    <div className="d-flex">
                      <div
                        className="ticket-user-badge"
                        style={{
                          backgroundColor: ticket.color,
                        }}
                      >
                        {ticket.userType[0]}
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <h6 className="mb-0 ticket-title">{ticket.title}</h6>
                        <p className="mb-0 ticket-msg">
                          {ticket.message.slice(0, 30)}...
                        </p>
                        <p
                          className={`ticket-status ${
                            ticket.status.toLowerCase().replace(" ", "-")
                          }`}
                        >
                          {ticket.status}
                        </p>
                      </div>
                      <div className="ticket-time">{ticket.time}</div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Main Ticket Details Section */}
          <div className="tickets-main" style={{ flex: "1", display: "flex", flexDirection: "column" }}>
            {selectedTicket ? (
              <>
                <div className="tickets-header d-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="mb-1 font-weight-bold">{selectedTicket.title}</h4>
                    <p className="text-muted mb-0">
                      {t("Status")}: {selectedTicket.status}
                    </p>
                  </div>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={handleCloseTicket}
                  >
                    {t("Close Ticket")}
                  </button>
                </div>

                <div className="tickets-content" style={{ flex: "1", overflowY: "auto" }}>
                  {chatMessages.map((msg, index) => (
                    <div
                      key={index}
                      className={`chat-message-container ${
                        msg.sender === "You" ? "chat-right" : "chat-left"
                      }`}
                    >
                      <div className="chat-message-header">
                        <strong>{msg.sender}</strong>
                        <span className="chat-time">{msg.time}</span>
                      </div>
                      {msg.type === "text" || !msg.type ? (
                        <p className="chat-message">{msg.message}</p>
                      ) : msg.type === "image" ? (
                        <img src={msg.value} alt="Attachment" className="chat-image" />
                      ) : msg.type === "audio" ? (
                        <audio controls className="chat-audio">
                          <source src={msg.value} type="audio/mp3" />
                        </audio>
                      ) : msg.type === "video" ? (
                        <video controls className="chat-video">
                          <source src={msg.value} type="video/mp4" />
                        </video>
                      ) : null}
                    </div>
                  ))}
                </div>

                <div className="chat-section">
                  {selectedTicket.status === t("Resolved") ? (
                    <div className="alert alert-success w-100 text-center">
                      {t("This ticket is resolved and closed.")}
                    </div>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Type a message...")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSendMessage(e.target.value);
                            e.target.value = "";
                          }
                        }}
                      />
                      <button className="btn btn-primary ms-2">
                        {t("Send")}
                      </button>
                      <button className="btn btn-secondary ms-2">
                        <i className="bx bx-paperclip"></i> {t("Attach")}
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="alert alert-info text-center mt-4">
                {t("Select a ticket to view details.")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tickets;