import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// Importar apenas os idiomas necessários
import ptLocale from '@fullcalendar/core/locales/pt-br';
import itLocale from '@fullcalendar/core/locales/it';
import enLocale from '@fullcalendar/core/locales/en-gb';

import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import './Calendar.css';

function Calendar() {
  const { t, i18n } = useTranslation();
  const calendarRef = useRef(null);

  const [locale, setLocale] = useState('en'); // Valor padrão

  const languageOptions = {
    en: enLocale,
    pt: ptLocale,
    it: itLocale,
  };

  // Sincronizar o idioma do calendário com a sessão
  useEffect(() => {
    // Obter o idioma preferido da sessão
    const preferredLanguage = sessionStorage.getItem('preferred_language') || 'en';
    
    // Atualizar o estado do idioma
    setLocale(preferredLanguage);

    // Sincronizar com o sistema de tradução
    i18n.changeLanguage(preferredLanguage);
  }, []);

  const handleEventClick = (info) => {
    alert(`${t('event')}: ${info.event.title}`);
    info.jsEvent.preventDefault();
  };

  const events = [
    { title: t('allDayEvent'), start: '2024-12-01' },
    { title: t('longEvent'), start: '2024-12-07', end: '2024-12-10' },
    // Outros eventos
  ];

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('calendar')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('calendar')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="calendar-container">
          <h1>{t('calendar')}</h1>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            }}
            events={events}
            locale={languageOptions[locale]} // Define o idioma dinamicamente
            selectable
            editable
            navLinks
            nowIndicator
            dayMaxEvents
            eventClick={handleEventClick}
            height="auto"
          />
        </div>
      </div>
    </div>
  );
}

export default withAuth(Calendar, ['admin', 'mechanic', 'receptionist']);