import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import API_BASE_URL from '../config';
import logo from '../assets/images/logo.png'; // Certifique-se de que o logotipo está na pasta correta

function Terms() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [clientData, setClientData] = useState(null); // Dados do cliente
  const [accepted, setAccepted] = useState(false);
  const id = searchParams.get('id');

  useEffect(() => {
    if (id) {
      fetch(`${API_BASE_URL}/api/client/${id}`)
        .then((res) => res.json())
        .then((data) => setClientData(data))
        .catch(() => setMessage('Errore durante il caricamento dei dati del cliente.'));
    }
  }, [id]);

  const handleAcceptTerms = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/accept-terms`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Ha accettato i termini con successo!');
        setAccepted(true);
      } else {
        setMessage(data.error || 'Errore durante l\'accettazione dei termini.');
      }
    } catch (error) {
      setMessage('Errore durante l\'accettazione dei termini.');
    }
  };

  return (
    <div style={{ padding: '40px', fontFamily: 'Arial, sans-serif', lineHeight: '1.8', color: '#333' }}>
      {/* Logotipo */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={logo} alt="3B Auto Logo" style={{ maxWidth: '150px' }} />
      </div>

      <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '20px' }}>
        Termini e condizioni del trattamento dati e privacy
      </h1>

      <div style={{ maxWidth: '800px', margin: '0 auto' }}>
        <h2>Informativa al sensi dell'art. 13 del Regolamento europeo 2016/679 e consenso</h2>
        <p>
          Ai sensi dell'art. 13 del Regolamento europeo (UE) 2016/679 (di seguito GDPR), e in relazione al dati personali di cui la nostra azienda entrerà in possesso, Le comunichiamo quanto segue:
        </p>

        <h2>Titolare del trattamento e responsabile della protezione dei dati personali</h2>
        <p>
          Titolare del trattamento è la 3B Auto di Elidir Busnello e C. Sas nella persona di Elidir Busnello con domicilio eletto in via Sandro Pertini 20, Marmirolo (MN), 46045.
          Il Titolare può essere contattato mediante email all'indirizzo <strong>38Auto3B@gmail.com</strong>.
          L'azienda del Titolare non ha nominato un responsabile della protezione dei dati personali (RPD ovvero, data protection officer, DPO).
        </p>

        <h2>Finalità del trattamento dei dati</h2>
        <p>
          Il trattamento è finalizzato alla corretta e completa esecuzione del contratto e i suoi dati saranno trattati anche al fine di:
        </p>
        <ul>
          <li>Adempiere agli obblighi previsti in ambito fiscale e contabile;</li>
          <li>Rispettare gli obblighi incombenti sulla nostra azienda e previsti dalla normativa vigente.</li>
        </ul>
        <p>
          I dati personali potranno essere trattati a mezzo sì di archivi cartacei che informatici (ivi compresi dispositivi portatili) e trattati con modalità strettamente necessarie a far fronte alle finalità sopra indicate.
        </p>

        <h2>Base giurídica del trattamento</h2>
        <p>
          La nostra azienda tratta I Suoi dati personali lecitamente, laddove il trattamento:
        </p>
        <ul>
          <li>Sia necessario all'esecuzione del contratto di cui Lei è parte o all'esecuzione di misure precontrattuali adottate su richiesta;</li>
          <li>Sia necessario per adempiere un obbligo legale incombente sulla nostra azienda;</li>
          <li>Sia basato sul consenso espresso.</li>
        </ul>

        <h2>Conseguenze della mancata comunicazione dei dati personali</h2>
        <p>Con riguardo ai dati personali relativi all'esecuzione del contratto di cui Lei è parte o relativi all'adempimento ad un obbligo normativo (ad esempio gli adempimenti legati alla tenuta delle scritture contabili e fiscali), la mancata comunicazione dei dati personali impedisce il perfezionarsi del rapporto contrattuale stesso.</p>

        <h2>Conservazione dei dati</h2>
        <p>
          I Suoi dati personali, oggetto di trattamento per le finalità sopra indicate, saranno conservati per il periodo di tempo obbligatorio per le finalità fiscali o per altre finalità previste da norme di legge.
        </p>
        <p>
          Per scopi commerciali il periodo di conservazione dei medesimi sarà di anni 10 o fino alla data di richiesta della loro cancellazione.
        </p>

        <h2>Comunicazione del dati</h2>
        <p>
          I Suoi dati personali potranno essere comunicati a:
        </p>
        <ul>
          <li>Consulenti e commercialisti o altri legali che erogano prestazioni funzionali al fini sopra indicati;</li>
          <li>Istituti bancari e assicurativi che erogano prestazioni funzionali ai fini sopra indicati;</li>
          <li>Soggetti che elaborano i dati in esecuzione di specifici obblighi di legge;</li>
          <li>Autorità giudiziarie o amministrative, per l'adempimento degli obblighi di legge.</li>
        </ul>

        <h2>Profilazione e Diffusione del dati</h2>
        <p>
          I Suoi dati personali non saranno soggetti a diffusione né ad alcun processo decisionale automatizzato, ivi compresa la profilazione.
        </p>

        <h2>Diritti dell'interessato</h2>
        <p>
          Tra i diritti a Lei riconosciuti dal GDPR rientrano quelli di:
        </p>
        <ul>
          <li>Chiedere alla nostra azienda l'accesso al Suoi dati personali ed alle informazioni relative agli stessi;</li>
          <li>Richiedere la rettifica, cancellazione o limitazione dei dati;</li>
          <li>Opporsi al trattamento dei dati;</li>
          <li>Richiedere la portabilità dei dati;</li>
          <li>Proporre reclamo all'autorità di controllo.</li>
        </ul>
        <p>
          - Revocare il consenso in qualsiasi momento, limitatamente alle ipotesi in cui il trattamento sia basato sul Suo consenso per una o più specifiche finalità.
        </p>

        {clientData && (
          <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '15px', marginBottom: '20px', backgroundColor: '#f9f9f9' }}>
            <h3 style={{ marginBottom: '15px', color: '#007bff' }}>Dati del Cliente</h3>
            <p><strong>Nome:</strong> {clientData.name}</p>
            <p><strong>Cognome:</strong> {clientData.surname}</p>
            <p><strong>Email:</strong> {clientData.email}</p>
            <p><strong>Telefono:</strong> {clientData.phone}</p>
            <p><strong>Codice Fiscale:</strong> {clientData.cf}</p>
            {clientData.ragioneSociale && <p><strong>Ragione Sociale:</strong> {clientData.ragioneSociale}</p>}
            {clientData.partitaIva && <p><strong>Partita IVA:</strong> {clientData.partitaIva}</p>}
            {clientData.pec && <p><strong>PEC:</strong> {clientData.pec}</p>}
            {clientData.codSdi && <p><strong>Codice SDI:</strong> {clientData.codSdi}</p>}
            <p><strong>Data di Registrazione:</strong> {new Date(clientData.createdAt).toLocaleDateString('it-IT')}</p>
          </div>
        )}

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <p style={{ fontSize: '0.9rem', color: '#555', marginBottom: '10px' }}>
          Cliccando su questo pulsante, accetti tutti i termini e condizioni di trattamento dei dati e privacy.
        </p>
        {accepted ? (
          <p style={{ color: 'green', fontWeight: 'bold' }}>Termini accettati con successo!</p>
        ) : (
          <button
            onClick={handleAcceptTerms}
            style={{
              backgroundColor: '#007bff',
              color: '#fff',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            Accetto i Termini e Condizioni
          </button>
        )}
      </div>

        {message && (
          <p style={{ color: accepted ? 'green' : 'red', marginTop: '10px', fontWeight: 'bold' }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
}

export default Terms;