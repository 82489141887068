import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ClientRegister from './pages/ClientRegister';
import ClientList from './pages/ClientList'; // Importando a página ClientList
import Terms from './pages/Terms'; // Importando a página Terms
import Profile from './pages/Profile'; // Importando a página Profile
import UserList from './pages/UserList'; // Importando a página UserList
import UserRegister from './pages/UserRegister'; // Importando a página UserRegister
import Work from './pages/Work';
import Documents from './pages/Documents';
import AuthorizedLocations from './pages/AuthorizedLocations';
import CustomerCare from './pages/CustomerCare'; // Importando a pagina costumerCare
import Calendar from './pages/Calendar'; // Importando a pagina Calendar
import Tickets from './pages/Tickets'; // Importando a pagina Tickets
import { useTranslation } from 'react-i18next';

function Layout({ children, toggleSidebar, isSidebarVisible }) {
  const location = useLocation();
  const showSidebar = location.pathname !== '/login'; // Sidebar visível apenas fora da página de login

  return (
    <div className={`app-container d-flex ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
      {showSidebar && <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />}
      <div
        className="main-content"
        style={{
          paddingTop: '70px',
          marginLeft: showSidebar && isSidebarVisible ? '240px' : '0',
          transition: 'margin-left 0.3s',
        }}
      >
        {showSidebar && <Header toggleSidebar={toggleSidebar} />}
        {children}
      </div>
    </div>
  );
}

function ProtectedRoute({ children, allowedRoles = [] }) {
  const token = sessionStorage.getItem('token'); // Token armazenado na sessão
  const role = sessionStorage.getItem('role'); // Papel do usuário armazenado

  if (!token) {
    console.warn('Usuário não está logado. Redirecionando para login.');
    return <Navigate to="/login" replace state={{ message: 'Por favor, faça login para acessar esta página.' }} />;
  }

  // Validação de papéis
  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    console.warn('Usuário não possui permissão para acessar esta página.');
    return <Navigate to="/unauthorized" replace state={{ message: 'Acesso negado a esta página.' }} />;
  }

  return children;
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Recupera o idioma preferido do sessionStorage e altera o idioma
    const preferredLanguage = sessionStorage.getItem('preferred_language');
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage); // Define o idioma ao carregar a aplicação
    }
  }, [i18n]); // Adiciona `i18n` como dependência
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const isLoggedIn = !!sessionStorage.getItem('token'); // Verifica se o usuário está logado

  return (
    <Router>
      <Routes>
        {/* Rota para Login */}
        <Route path="/login" element={<Login />} />

        {/* Rota protegida para Dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Recepcao de Cliente */}
        <Route
          path="/customer-care"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <CustomerCare />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Calendar */}
        <Route
          path="/calendar"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Calendar />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Tickets */}
        <Route
          path="/tickets"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist', 'mechanic']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Tickets />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Registro de Clientes */}
        <Route
          path="/ClientRegister"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ClientRegister />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Lista de Clientes */}
        <Route
          path="/ClientList"
          element={
            <ProtectedRoute allowedRoles={['admin', 'receptionist']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <ClientList />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Lista de Usuários */}
        <Route
          path="/userList"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <UserList />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Registrar Usuário */}
        <Route
          path="/userRegister"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <UserRegister />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Perfil */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Work */}
        <Route
          path="/work"
          element={
            <ProtectedRoute>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Work />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Documentos */}
        <Route
          path="/work/documents"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <Documents />
              </Layout>
            </ProtectedRoute>
          }
        />

        {/* Rota para Termos e Condições */}
        <Route path="/terms" element={<Terms />} />

        {/* Rota de Redirecionamento inicial */}
        <Route
          path="/"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
        />

        {/* Rota para Locais Autorizados */}
        <Route
          path="/authorized-locations"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Layout toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}>
                <AuthorizedLocations />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* Rota para páginas não encontradas */}
        <Route
          path="*"
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" state={{ message: 'Rota não encontrada, redirecionado para o login.' }} />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;