import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';
import API_BASE_URL from '../config';

const Work = () => {
  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [workLogs, setWorkLogs] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const { t } = useTranslation();
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [totalMonthlyHours, setTotalMonthlyHours] = useState('0h 0m');

  const getCurrentMonthYear = () => {
    const now = new Date();
    return { month: now.getMonth() + 1, year: now.getFullYear() };
  };
  
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthYear());

  const processAvailableDates = (dates) => {
    const uniqueMonths = [];
    dates.forEach((date) => {
      if (date.checkin_time) {
        const checkInDate = new Date(date.checkin_time);
        const year = checkInDate.getFullYear();
        const month = checkInDate.getMonth() + 1;
  
        if (!uniqueMonths.find((item) => item.year === year && item.month === month)) {
          uniqueMonths.push({ year, month });
        }
      }
    });
    return uniqueMonths;
  };

  const calculateMonthlyHours = (logs) => {
    let totalMinutes = 0;

    logs.forEach((log) => {
      if (log.duration) {
        const [hours, minutes] = log.duration.split(' ').map((val) => parseInt(val.replace(/\D/g, ''), 10));
        totalMinutes += hours * 60 + minutes;
      }
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    return `${totalHours}h ${remainingMinutes}m`;
  };

  const fetchWorkData = async (month, year) => {
    try {
      const token = sessionStorage.getItem('token');
      let logs = [];
  
      if (month && year) {
        // Filtro por mês e ano
        const response = await axios.get(`${API_BASE_URL}/api/work/logs`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { month, year },
        });
        logs = response.data;
      } else {
        // Todo o período
        const response = await axios.get(`${API_BASE_URL}/api/work/dates`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        logs = response.data;
      }
  
      if (!Array.isArray(logs) || logs.length === 0) {
        console.warn('Nenhum log encontrado.');
        setWorkLogs([]);
        setTotalMonthlyHours('0h 0m');
        return;
      }
  
      // Atualiza os dados dos logs e calcula as horas totais
      setWorkLogs(logs);
      setTotalMonthlyHours(calculateMonthlyHours(logs));
  
      // Atualiza as datas disponíveis (apenas para "Todo o Período")
      if (!month && !year) {
        const dates = processAvailableDates(logs);
        setAvailableDates(dates);
      }
  
      console.log('Dados retornados:', logs);
    } catch (error) {
      console.error('Erro ao buscar dados de trabalho:', error);
      setWorkLogs([]);
      setTotalMonthlyHours('0h 0m');
    }
  };
  
  const handleMonthChange = (e) => {
    const value = e.target.value;
  
    if (!value) {
      // "Todo o Período" selecionado
      setSelectedMonth(null);
      fetchWorkData(); // Busca todos os dados
    } else {
      const [year, month] = value.split('-').map(Number);
      setSelectedMonth({ year, month });
      fetchWorkData(month, year);
    }
  };
  
  // Ajuste inicial para carregar o mês atual
  useEffect(() => {
    const { year, month } = getCurrentMonthYear();
    setSelectedMonth({ year, month });
    fetchWorkData(month, year); // Busca os dados do mês atual
  }, []);
  

  const getTranslatedMonth = (month) => {
    const months = [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december'),
    ];
    return months[month - 1];
  };

  if (!Array.isArray(workLogs)) {
    setWorkLogs([]);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ latitude, longitude });
        verifyLocation(latitude, longitude);
      },
      (error) => {
        console.error('Erro ao obter a localização:', error);
        alert(t('notPermitedLocationAble'));
      }
    );

    fetchWorkData(); // Combina fetchAvailableDates e fetchWorkLogs
    checkCheckInStatus();
  }, []);

    useEffect(() => {
        if (hasCheckedIn) {
          const interval = setInterval(() => {
            const duration = Date.now() - new Date(hasCheckedIn).getTime();
            const hours = Math.floor(duration / 3600000);
            const minutes = Math.floor((duration % 3600000) / 60000);
            const seconds = Math.floor((duration % 60000) / 1000);
            setTimer({ hours, minutes, seconds });
          }, 1000);
      
          return () => clearInterval(interval);
        }
  }, [hasCheckedIn]);

  useEffect(() => {
    if (currentPosition) {
      verifyLocation(currentPosition.latitude, currentPosition.longitude);
    }
  }, [currentPosition]);

  const verifyLocation = async (latitude, longitude) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/work/verify-location`,
        { latitude, longitude },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }
      );

      console.log('Resposta do backend:', response.data);
      setLocationAllowed(response.data.allowed);
    } catch (error) {
      console.error('Erro ao verificar localização:', error.response?.data || error.message);
      setLocationAllowed(false);
    }
  };

  const checkCheckInStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/work/checkin-status`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      if (response.data.hasCheckedIn) {
        setHasCheckedIn(new Date(response.data.checkInTime));
      } else {
        setHasCheckedIn(false);
      }
    } catch (error) {
      console.error('Erro ao verificar status de check-in:', error);
    }
  };

  const handleCheck = async (type) => {
    if (!locationAllowed) {
      alert(t('notAuthorizedLocation'));
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/work/check`,
        { type, location: currentPosition },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } }
      );

      if (type === 'check-in') {
        setHasCheckedIn(new Date());
      } else {
        setHasCheckedIn(false);
      }
      if (selectedDate) fetchWorkData(selectedDate);
    } catch (error) {
      console.error('Erro ao registrar check-in ou check-out:', error);
      alert(t('errorMsgRegister'));
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="container mt-5">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="location-status">
              {locationAllowed ? (
                <p className="text-authorized">
                  <i className="fas fa-check-circle"></i> {t('authorizedLocation')}
                </p>
              ) : (
                <p className="text-not-authorized">
                  <i className="fas fa-times-circle"></i> {t('notAuthorizedLocation')}
                </p>
              )}
            </div>

            <div>
              {hasCheckedIn ? (
                <button
                  className="btn btn-checkout"
                  onClick={() => handleCheck('check-out')}
                  disabled={!locationAllowed}
                >
                  <i className="fas fa-sign-out-alt"></i> {t('check-out')}
                </button>
              ) : (
                <button
                  className="btn btn-checkin"
                  onClick={() => handleCheck('check-in')}
                  disabled={!locationAllowed}
                >
                  <i className="fas fa-sign-in-alt"></i> {t('check-in')}
                </button>
              )}
            </div>
          </div>
          {hasCheckedIn ? (
            <div className="timer-clock">
                <span>{String(timer.hours).padStart(2, '0')}</span>:
                <span>{String(timer.minutes).padStart(2, '0')}</span>:
                <span>{String(timer.seconds).padStart(2, '0')}</span>
            </div>
            ) : (
            <div className="timer-clock inactive">
                <span>00</span>:<span>00</span>:<span>00</span>
            </div>
            )}

<div className="mt-3">
  <div className="d-flex justify-content-between align-items-center mb-4">
    <h4 className="section-title">{t('workLogs')}</h4>
        <div className="filter-container">
            <div className="btn-group">
              <select
                value={
                  selectedMonth
                    ? `${selectedMonth.year}-${selectedMonth.month.toString().padStart(2, '0')}`
                    : ''
                }
                onChange={handleMonthChange}
              >
                <option value="">{t('allPeriod')}</option>
                {availableDates.map((date, index) => (
                  <option key={index} value={`${date.year}-${date.month.toString().padStart(2, '0')}`}>
                    {`${getTranslatedMonth(date.month)} ${date.year}`}
                  </option>
                ))}
              </select>
            </div>
            </div>
        </div>

        <div className="total-hours">
          <h5>{t('totalMonthlyHours')}: {totalMonthlyHours}</h5>
        </div>

        <div className="table-responsive">
            <table className="table table-bordered table-striped custom-table modern-table">
              <thead className="table-light">
                  <tr>
                  <th className="text-center">{t('date')}</th>
                  <th className="text-center">{t('checkin_time')}</th>
                  <th className="text-center">{t('checkout_time')}</th>
                  <th className="text-center">{t('duration')}</th>
                  </tr>
              </thead>
              <tbody>
                {workLogs.map((log, index) => (
                  <tr key={index}>
                    <td>{log.checkin_time ? new Date(log.checkin_time).toLocaleDateString('it-IT') : '-'}</td>
                    <td>{log.checkin_time ? new Date(log.checkin_time).toLocaleTimeString('it-IT') : '-'}</td>
                    <td>{log.checkout_time ? new Date(log.checkout_time).toLocaleTimeString('it-IT') : '-'}</td>
                    <td>{log.duration || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-2 text-muted small text-center">
              <i className="fas fa-info-circle me-2"></i>{t('tooltipLunchDiscount')}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Work, ['admin', 'mechanic', 'receptionist']);