import React, { useEffect } from 'react';
import MetisMenu from 'metismenu';
import '../assets/plugins/metismenu/css/metisMenu.min.css';
import { NavLink, useLocation } from 'react-router-dom';
import '../assets/css/sidebar.css';
import { useTranslation } from 'react-i18next';
import withAuth from '../hoc/withAuth';

function Sidebar({ isVisible, toggleSidebar }) {
  const { t } = useTranslation();
  const location = useLocation();

  // Obtém a role do usuário da sessão
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    new MetisMenu("#menu");
  }, []);

  // Verifica permissões com base no role
  const hasPermission = (allowedRoles) => allowedRoles.includes(role);

  // Função para verificar se a rota atual corresponde ao item do menu
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <aside className={`sidebar ${isVisible ? "open" : "closed"}`}>
      <div className="sidebar-content">
        <nav className="sidebar-nav">
          <ul className="metismenu" id="menu">
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/dashboard") ? "mm-active" : ""}>
                <NavLink to="/dashboard" className="sidebar-link">
                  <i className="bx bx-home-alt"></i>
                  Dashboard
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/customer-care") ? "mm-active" : ""}>
                <NavLink to="/customer-care" className="sidebar-link">
                  <i className="bx bx-id-card"></i> {/* Alterado para bx-id-card */}
                  {t('customerCare')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/calendar") ? "mm-active" : ""}>
                <NavLink to="/calendar" className="sidebar-link">
                  <i className="bx bx-calendar"></i> {/* Ícone para o calendário */}
                  {t('calendar')} {/* Texto traduzido */}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "receptionist"]) && (
              <li className={isActive("/Client") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-group"></i>
                  {t('clients')}
                </a>
                <ul>
                  <li><NavLink to="/ClientRegister">{t('newClient')}</NavLink></li>
                  <li><NavLink to="/ClientList">{t('listClient')}</NavLink></li>
                </ul>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/RepairAuto") ? "mm-active" : ""}>
                <NavLink to="/RepairAuto" className="sidebar-link">
                  <i className="bx bx-wrench"></i>
                  Repair Auto
                </NavLink>
              </li>
            )}
            {role === "admin" && (
              <li className={isActive("/profile") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-user"></i>
                  {t('profile')}
                </a>
                <ul>
                  <li><NavLink to="/profile">{t('settings')}</NavLink></li>
                  <li><NavLink to="/userRegister">{t('newUser')}</NavLink></li>
                  <li><NavLink to="/userList">{t('listUsers')}</NavLink></li>
                </ul>
              </li>
            )}
            {role !== "admin" && hasPermission(["mechanic", "receptionist"]) && (
              <li className={isActive("/profile") ? "mm-active" : ""}>
                <NavLink to="/profile" className="sidebar-link">
                  <i className="bx bx-user"></i>
                  {t('profile')}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin"]) && (
              <li className={isActive("/settings") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-cog"></i>
                  Settings
                </a>
                <ul>
                  <li><NavLink to="/settings/general">General</NavLink></li>
                  <li><NavLink to="/settings/security">Security</NavLink></li>
                </ul>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/tickets") ? "mm-active" : ""}>
                <NavLink to="/tickets" className="sidebar-link">
                  <i className="bx bx-message-detail"></i> {/* Ícone de envelope para Tickets */}
                  {t('tickets')} {/* Texto traduzido */}
                </NavLink>
              </li>
            )}
            {hasPermission(["admin", "mechanic", "receptionist"]) && (
              <li className={isActive("/work") ? "mm-active" : ""}>
                <a href="#!" className="has-arrow sidebar-link">
                  <i className="bx bx-briefcase"></i>
                  {t('work')}
                </a>
                <ul>
                  <li><NavLink to="/work">{t('check')}</NavLink></li>
                  <li><NavLink to="/work/documents">{t('documents')}</NavLink></li>
                </ul>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default withAuth(Sidebar, ['admin', 'mechanic', 'receptionist']);