import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CustomerCare.css';

function CustomerCare() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [isExistingClient, setIsExistingClient] = useState(false);
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    carModel: '',
    carYear: '',
    carPlate: '',
    carProblem: '',
    estimatedCost: '',
  });

  const steps = [
    { id: 1, title: t('Personal Information'), number: '1', description: t('Client details') },
    { id: 2, title: t('Car Details'), number: '2', description: t('Car information') },
    { id: 3, title: t('Problem Description'), number: '3', description: t('Describe the problem') },
    { id: 4, title: t('Summary and Cost'), number: '4', description: t('Review and submit') },
  ];

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClientTypeChange = (e) => {
    setIsExistingClient(e.target.value === 'existing');
    if (e.target.value === 'existing') {
      setFormData({
        clientName: 'John Doe',
        clientEmail: 'johndoe@example.com',
        clientPhone: '1234567890',
        carModel: '',
        carYear: '',
        carPlate: '',
        carProblem: '',
        estimatedCost: '',
      });
    } else {
      setFormData({
        clientName: '',
        clientEmail: '',
        clientPhone: '',
        carModel: '',
        carYear: '',
        carPlate: '',
        carProblem: '',
        estimatedCost: '',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Final Form Data:', formData);
    // Aqui você pode enviar os dados para o backend usando uma requisição HTTP
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h4 className="form-section-title">{t('Personal Information')}</h4>
            <div className="mb-4">
              <label className="form-label">{t('Select Client Type')}</label>
              <select className="form-control" onChange={handleClientTypeChange}>
                <option value="new">{t('New Client')}</option>
                <option value="existing">{t('Existing Client')}</option>
              </select>
            </div>
            <div className="row g-4">
              <div className="col-md-6">
                <label className="form-label">{t('Name')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleChange}
                  placeholder={t('Enter your name')}
                  disabled={isExistingClient}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Email')}</label>
                <input
                  type="email"
                  className="form-control"
                  name="clientEmail"
                  value={formData.clientEmail}
                  onChange={handleChange}
                  placeholder={t('Enter your email')}
                  disabled={isExistingClient}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Phone')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="clientPhone"
                  value={formData.clientPhone}
                  onChange={handleChange}
                  placeholder={t('Enter your phone number')}
                  disabled={isExistingClient}
                  required
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h4 className="form-section-title">{t('Car Details')}</h4>
            <div className="row g-4">
              <div className="col-md-6">
                <label className="form-label">{t('Model')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="carModel"
                  value={formData.carModel}
                  onChange={handleChange}
                  placeholder={t('Enter car model')}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Year')}</label>
                <input
                  type="number"
                  className="form-control"
                  name="carYear"
                  value={formData.carYear}
                  onChange={handleChange}
                  placeholder={t('Enter car year')}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Plate')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="carPlate"
                  value={formData.carPlate}
                  onChange={handleChange}
                  placeholder={t('Enter car plate')}
                  required
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h4 className="form-section-title">{t('Problem Description')}</h4>
            <div className="form-group">
              <label className="form-label">{t('Description')}</label>
              <textarea
                className="form-control"
                name="carProblem"
                value={formData.carProblem}
                onChange={handleChange}
                rows="5"
                placeholder={t('Describe the issue with the car')}
                required
              ></textarea>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h4 className="form-section-title">{t('Summary and Cost')}</h4>
            <div className="row g-4">
              <div className="col-md-12">
                <p>
                  <strong>{t('Name')}:</strong> {formData.clientName}
                </p>
                <p>
                  <strong>{t('Car')}:</strong> {formData.carModel} - {formData.carYear} ({formData.carPlate})
                </p>
                <p>
                  <strong>{t('Problem')}:</strong> {formData.carProblem}
                </p>
              </div>
              <div className="col-md-6">
                <label className="form-label">{t('Estimated Cost')}</label>
                <input
                  type="text"
                  className="form-control"
                  name="estimatedCost"
                  value={formData.estimatedCost}
                  onChange={handleChange}
                  placeholder={t('Enter estimated cost')}
                  required
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">{t('customerCare')}</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="/dashboard"><i className="bx bx-home-alt"></i></a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('customerCare')}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* Progress Bar */}
            <div className="progress-bar-container mb-4">
              {steps.map((step, index) => (
                <React.Fragment key={step.id}>
                  <div
                    className={`progress-step ${currentStep === step.id ? 'active' : ''} ${
                      currentStep > step.id ? 'completed' : ''
                    }`}
                    onClick={() => handleStepClick(step.id)}
                  >
                    <div className="step-icon">{step.number}</div> {/* Número da etapa */}
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`bs-stepper-line ${
                        currentStep > step.id ? 'completed' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <form onSubmit={handleSubmit}>
              {renderStep()}
              <div className="d-flex justify-content-between mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  disabled={currentStep === 1}
                >
                  {t('Previous')}
                </button>
                {currentStep === 4 ? (
                  <button type="submit" className="btn btn-primary">
                    {t('Submit')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setCurrentStep((prev) => prev + 1)}
                  >
                    {t('Next')}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerCare;